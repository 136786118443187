<template>
  <div class="page-container">
    <div class="header-container">
      <div class="header-img-container">
        <img src="../assets/capitol/logo.png" >
      </div>
      <div class="header-button-container">
        <button v-on:click="onSwitchLocale('en_US')">{{ $t("views.header.subMenu_1a") }}</button>
        <button v-on:click="onSwitchLocale('zh_CN')">{{ $t("views.header.subMenu_1b") }}</button> 
      </div>
    </div>
    <div class="content-container">
      <div class="content-banner-container">
      </div>
      <div class="contact-info-container">
        <div class="contact-info-title-container">
          {{ $t("views.home.contactInfo.title") }}
        </div>
        <div class="contact-info-content-container">
          {{ $t("views.home.contactInfo.hkAddress") }}
          <br />
          {{ $t("views.home.contactInfo.contactPerson_1") }}
          <br />
          <a href="mailto:henryyip@jxsjchem.com">henryyip@jxsjchem.com</a>
          <br />
          {{ $t("views.home.contactInfo.contactNo_1") }}
          <br /><br />
          {{ $t("views.home.contactInfo.cnAddress") }}
          <br />
          {{ $t("views.home.contactInfo.contactPerson_2") }}
          <br />
          <a href="mailto:chenx@jxsjchem.com">chenx@jxsjchem.com</a>
          <br />
          {{ $t("views.home.contactInfo.contactNo_2") }}
        </div>
      </div>
      <br />
      <br />
      <div class="company-info-container">
        {{ $t("views.home.content.p1") }}
        <br /><br />
        {{ $t("views.home.content.p2a") }}
        {{ $t("views.home.content.p2b") }}
        <a href="http://www.chinasanjiang.com/">www.chinasanjiang.com</a>
        {{ $t("views.home.content.p2c") }}
      </div>
    </div>
  </div>
</template>

<script>
import util from "@/util";

export default {
  name: "home",

  methods: {
    onSwitchLocale(locale) {
      util.locale.asyncLoadLocale(locale);
    }
  },
};
</script>

<style>
.page-container {
  flex-direction: column;
  display: flex;
  position: relative;
}

.content-container {
  display: flex;
  flex-direction: column;
  top: 120px;
  position: relative;
  padding-bottom: 150px;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;   
  position: fixed;
  z-index: 1;
  height: 10%;
}

 .header-img-container img {
  object-fit: contain;
  max-width: 600px;
  overflow: hidden;
  padding: 5px;
}

.header-button-container {
  align-self: center;
  padding: 15px;
}

.header-button-container button {
  font-family: "Helvetica",Verdana,Arial,sans-serif;
  font-size: 16px;
  color: #70ad47;
  background-color: inherit;
  align-content: center;
  justify-content: right;
  border: none;
  cursor: pointer;
  justify-content: space-between;
}

.content-banner-container {
  content: url(../assets/capitol/banner.jpg);
  padding: 0 0 50px 0;
}

.contact-info-container {
  margin: 0 250px 0 200px;
  padding: 10px;
  border: 0;
}

.contact-info-title-container {
  font-family: "Helvetica",Verdana,Arial,sans-serif;
  font-size: 36px;
  color: #70ad47;
  line-height: 2;
  padding-bottom: 30px;
}

.contact-info-content-container {
  font-family: "Microsoft YaHei", Verdana, Arial, sans-serif !important;
  font-size: 20px;
  text-align: justify;
  line-height: 2;
  color: #808080;
}

.contact-info-a {
  color: #428bca;
}

a {
  color: #428bca;
  text-decoration: none;
}

.company-info-container {
  margin: 0 250px 0 200px;
  padding: 10px;
  font-family: "Helvetica",Verdana,Arial,sans-serif;
  font-size: 20px;
  text-align: justify;
  line-height: 1.75;
  color: #808080;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .contact-info-container {
    margin: 0 60px 0 60px;
    padding: 0;
  }

  .company-info-container {
    margin: 0 60px 0 60px;
    padding: 0;
  }
}

@media (min-width: 768px) and (max-width: 991.99px) {
  .contact-info-container {
    margin: 0 60px 0 60px;
    padding: 0;
  }

  .company-info-container {
    margin: 0 60px 0 60px;
    padding: 0;
  }

  .header-img-container {
    align-self: center;
  }

  .header-img-container img {
    zoom: 110%;
  }

  .header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 15%;
  }
}

@media (max-width: 767.99px) {    
  .header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 15%;
  }

  .header-img-container {
    align-self: center;
  }

  .header-img-container img {
    zoom: 95%;
  }
  
  .content-banner-container {
    display: none;
  }
  
  .contact-info-container {
    margin: 0 60px 0 60px;
    padding: 0;
  }

  .company-info-container {
    margin: 0 60px 0 60px;
    padding: 0;
  }
}
</style>
