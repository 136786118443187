import axios from "axios";
import router from "@/router";
import store from "@/store";

const fetchTool = {
  axiosConfig: {
    timeout: 1000 * 10,
  },
  axiosRetryConfig: {
    // 最大重新发起请求次数
    maxRetryCount: 2,
    // 重新发起请求延迟
    retryDelay: 1000,
  },
  /**
   * 设置拦截器
   *
   * @param instance axios object
   */
  setInterceptors(instance) {
    // 请求拦截器
    instance.interceptors.request.use(
      (config) => {
        // accessToken 带入请求头
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.error(error)
    );

    // 响应拦截器
    instance.interceptors.response.use(
      // 请求成功
      (res) => Promise.resolve(res.data),
      // 请求失败
      (error) => {
        return this.errorHandler(error);
      }
    );
  },
  /**
   * 处理请求失败
   *
   * @param error axios error object
   */
  errorHandler(error) {
    if (error.response) {
      switch (error.response.status) {
        // 401 未能验证用户身份
        case 401:
          this.redirectToLogin();
          break;
        // 403 令牌过期
        case 403:
          // return Promise.reject(error);
          this.redirectToLogin();
          break;
        // 422 请求的格式正确 但是内容服务器拒绝处理
        case 422:
          return Promise.reject(error);
        // 404 请求不存在
        case 404:
          console.log(404);
          break;
        default:
          console.log(error);
      }
    } else {
      // 未知错误重新发起请求
      return this.retryRequest(error);
    }
    return Promise.reject(error);
  },
  /**
   * 重定向登录
   */
  async redirectToLogin() {
    await store.dispatch("account/asyncSignOut");
    const { path } = router.app.$route;
    if (path !== "/auth/login") {
      router.replace({
        path: "/auth/login",
        query: {
          redirect: router.currentRoute.fullPath,
        },
      });
    }
  },
  /**
   * 重新发起请求
   *
   * @param error axios error object
   */
  retryRequest(error) {
    let { retryInfo } = error.config.headers;
    if (!retryInfo) {
      // 将重新发起请求的信息设置到 headers 回调需要
      retryInfo = error.config.headers.retryInfo = {
        // 当前重新发起请求次数
        currentRetryCounnt: 0,
        ...this.axiosRetryConfig,
      };
    }

    // 验证是否已经到达最大重新发起请求次数
    if (retryInfo.currentRetryCounnt >= retryInfo.maxRetryCount) {
      console.error(`重新发起请求失败，地址: ${error.config.url}`);
      return Promise.reject(error);
    }

    // 自增
    retryInfo.currentRetryCounnt += 1;

    // 延迟重新发起请求
    const backoff = new Promise((resolve) => {
      setTimeout(() => resolve(), retryInfo.retryDelay);
    });

    return backoff.then(() => {
      console.log(
        `重新发起请求中，第 ${retryInfo.currentRetryCounnt}次，地址: ${error.config.url}`
      );

      return instance(error.config);
    });
  },
  /**
   * 刷新 access token 重新发起请求
   *
   * @param error axios error object
   */
  retryRequestWithAccessToken(error) {
    console.log(error);
  },
};

const instance = axios.create(fetchTool.axiosConfig);

fetchTool.setInterceptors(instance);

export default instance;
