<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import util from "@/util";

export default {
  async mounted() {
    // 初始化国际化
    await util.locale.asyncLoadLocale("zh_CN");
  },
};
</script>
