import util from "@/util";
import i18n from "@/locale";

const localeTool = {
  // 缓存已经加载的的国际化文件
  loadedLocaleList: [],
  /**
   * 更新 i18n
   *
   * @param newLocale 文件名[zh_CN, zh_TW, ...]
   */
  loadLocaleEffect(newLocale) {
    i18n.locale = newLocale;
    util.fetch.defaults.headers.common["Accept-Language"] = newLocale;
    return newLocale;
  },
  /**
   * 异步加载国际化文件
   *
   * @param newLocale 文件名[zh_CN, zh_TW, ...]
   */
  asyncLoadLocale(newLocale) {
    if (i18n.locale !== newLocale) {
      if (!this.loadedLocaleList.includes(newLocale)) {
        return import(
          /* webpackChunkName: "lang-[request]" */ `../../locale/resources/${newLocale}`
        ).then((msgs) => {
          i18n.setLocaleMessage(newLocale, msgs.default);
          this.loadedLocaleList.push(newLocale);
          return this.loadLocaleEffect(newLocale);
        });
      }
      return Promise.resolve(this.loadLocaleEffect(newLocale));
    }
    return Promise.resolve(newLocale);
  },
};

export default localeTool;
